import React, { forwardRef, ForwardRefRenderFunction } from "react";
import withAutoplay, { AutoplayProps } from "@gx/src/components/withAutoplay";
import styled from "@emotion/styled";
import { graphql, StaticQuery, withPrefix } from "gatsby";

const Button = styled.button<AutoplayProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	z-index: 1;
	background: none;
	border: none;
	display: ${(props) => (props.active ? "flex" : "none")};
	justify-content: center;
	align-items: center;
	img {
		display: block;
		width: 90px;
	}
`;

type Props = AutoplayProps;

const AutoplayButton: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
	{ active, ...rest },
	ref
) => (
	<StaticQuery
		query={graphql`
			query AutoplayQuery {
				allFile(filter: { name: { eq: "play" } }) {
					nodes {
						childImageSharp {
							resize(width: 90) {
								src
							}
						}
					}
				}
			}
		`}
	>
		{(data) => (
			<Button ref={ref} active={active} {...rest}>
				<img
					src={withPrefix(data.allFile.nodes[0].childImageSharp.resize.src)}
					alt="play"
				/>
			</Button>
		)}
	</StaticQuery>
);

export default withAutoplay(forwardRef(AutoplayButton));
