import React from "react";
import { ThemeProvider } from "../src/contexts/ThemeProvider";
import { BreakpointsProvider } from "../src/contexts/BreakpointsProvider";
import StylesProvider from "../src/contexts/StylesProvider";

export default ({ element, props }, pluginOptions) => {
	const providers = [];

	if (pluginOptions.styles !== false) {
		providers.push(StylesProvider);
	}

	if (pluginOptions.breakpoints !== false) {
		providers.push(BreakpointsProvider);
	}

	if (pluginOptions.theme !== false) {
		providers.push(ThemeProvider);
	}

	return providers.reduce(
		(el, ProviderClass) => <ProviderClass>{el}</ProviderClass>,
		element
	);
};
