import React, {
	useState,
	useCallback,
	createContext,
	useMemo,
	FC,
} from "react";

export const LoadingContext = createContext({
	loaded: false,
	loading: false,
	startLoading: () => {},
	stopLoading: () => {},
});

export default (WrappedComponent: FC<any>, initialLoading = false) => {
	const WithLoadingHOC: FC<any> = ({ ...props }) => {
		const [loading, setLoading] = useState(initialLoading);
		const [started, setStarted] = useState(initialLoading);

		const loaded = useMemo(() => !loading && started, [loading]);

		const startLoading = useCallback(() => {
			setLoading(true);
			setStarted(true);
		}, [setLoading, setStarted]);

		const stopLoading = useCallback(() => {
			setLoading(false);
		}, [setLoading]);

		return (
			<LoadingContext.Provider
				value={{
					loaded,
					loading,
					startLoading,
					stopLoading,
				}}
			>
				<WrappedComponent {...props} />
			</LoadingContext.Provider>
		);
	};

	return WithLoadingHOC;
};
