import React from "react";
import { graphql, StaticQuery, withPrefix } from "gatsby";
import styled from "@emotion/styled";

const LogoLink = styled.a`
	position: fixed;
	top: 0;
	left: 0;
	margin: 2.5vh 0 0 3vw;
	z-index: ${(props) => props.theme.zIndices.logo};
	display: block;
	width: 120px;

	img {
		display: block;
		width: 100%;
	}
`;

const Logo = () => (
	<StaticQuery
		query={graphql`
			query LogoQuery {
				allFile(filter: { name: { eq: "logo" } }) {
					nodes {
						childImageSharp {
							resize(width: 120) {
								src
							}
						}
					}
				}
			}
		`}
	>
		{(data) => (
			<LogoLink href={"https://sample-cm.com"}>
				<img
					src={withPrefix(data.allFile.nodes[0].childImageSharp.resize.src)}
					alt="logo"
				/>
			</LogoLink>
		)}
	</StaticQuery>
);

export default Logo;
