import React, {
	FC,
	ComponentType,
	createContext,
	useContext,
	useState,
	useCallback,
	useEffect,
} from "react";
import { PreloadLink } from "@gx";

interface OwnProps {}

type PreloadsProps = OwnProps;

export const PreloadsContext = createContext<{
	addPreloads: (
		links: Array<React.LinkHTMLAttributes<HTMLLinkElement>>
	) => void;
}>({
	addPreloads: () => {},
});

export default <P extends object>(
	WrappedComponent: ComponentType<P>
): FC<Omit<P, keyof PreloadsProps>> => {
	const withPreloads = ({ ...props }) => {
		const [preloads, setPreloads] = useState<
			Array<React.LinkHTMLAttributes<HTMLLinkElement>>
		>([]);

		const addPreloads = useCallback(
			(links: Array<React.LinkHTMLAttributes<HTMLLinkElement>>) =>
				setPreloads((previousValue) => [...previousValue, ...links]),
			[setPreloads]
		);

		return (
			<PreloadsContext.Provider
				value={{
					addPreloads,
				}}
			>
				{preloads.map((m, index) => (
					<PreloadLink key={index} {...m} />
				))}
				<WrappedComponent {...(props as P)} />
			</PreloadsContext.Provider>
		);
	};

	return withPreloads;
};

export const usePreloads = (
	links: Array<React.LinkHTMLAttributes<HTMLLinkElement>>
) => {
	const { addPreloads } = useContext(PreloadsContext);

	useEffect(() => {
		addPreloads(links);
	}, []);
};
