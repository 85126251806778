/** @jsx jsx */
import { jsx, ThemeProvider as ThemeEmotionProvider } from "@emotion/react";
import { FC, ComponentType } from "react";
import theme from "../theme";

interface OwnProps {}

type ThemeProps = OwnProps;

export const ThemeProvider: FC<any> = ({ children }) => (
	<ThemeEmotionProvider theme={theme}>{children}</ThemeEmotionProvider>
);

export default <P extends object>(
	WrappedComponent: ComponentType<P>
): FC<Omit<P, keyof ThemeProps>> => {
	const withTheme = ({ ...props }) => (
		<ThemeProvider>
			<WrappedComponent {...(props as P)} />
		</ThemeProvider>
	);

	return withTheme;
};
