import React from "react";
import WrapPage from "./src/components/WrapPage";
// import WrapRoot from "./src/components/WrapRoot"

// export const wrapRootElement = ({ element, ...rest }) => {
//   return <WrapRoot {...rest}>{element}</WrapRoot>
// }

export const wrapPageElement = ({ element, props }) => {
	return <WrapPage {...props}>{element}</WrapPage>;
};

export const onClientEntry = () => {
	// no-js class
	// document.documentElement.className = document.documentElement.className.replace(
	//   /no-js/,
	//   "js"
	// )
	// Polyfills
	// if (!(`IntersectionObserver` in window)) {
	//   import(`intersection-observer`)
	// }
	// Hack scroll
	// if ("scrollRestoration" in history) {
	// 	history.scrollRestoration = "manual";
	// }
};
