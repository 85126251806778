import wrapRootElement from "./gatsby/wrapRootElement";

export { wrapRootElement };

export const onClientEntry = (_, pluginOptions) => {
	if (
		pluginOptions.objectFit &&
		"objectFit" in document.documentElement.style !== false
	) {
		import(
			/* webpackChunkName: 'objectFitPolyfill' */ "objectFitPolyfill/dist/objectFitPolyfill.basic.min"
		);
	}
};
