import { css, Theme } from "@emotion/react";
import "../assets/fonts";
import "minireset.css/minireset.min.css";

export default (theme: Theme) => css`
	body {
		font-family: ${theme.fonts.body};

		font-size: ${theme.fontSizes.mobile};

		@media ${theme.breakpoints[1]} {
			font-size: ${theme.fontSizes.desktop};
		}
	}

	button {
		font-family: ${theme.fonts.body};
		font-weight: normal;
		font-size: 100%;
	}
`;
