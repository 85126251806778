import React, {
	createRef,
	FC,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { withPrefix } from "gatsby";
import styled from "@emotion/styled";
import { CSSTransition } from "react-transition-group";
import { useTheme } from "@emotion/react";
import {
	LoadingContext,
	MediaContext,
	useFontFaceObserver,
	usePreloads,
} from "@gx";
import { useVideoArtDirection, VideoStretch } from "@gx-ui";
import Mute from "@src/elements/Mute";
import AutoplayButton from "@src/elements/AutoplayButton";

const LoadingMute = styled(Mute)`
	position: absolute;
	bottom: 30px;
	right: 30px;
	padding-top: 15px;
	padding-bottom: 15px;
`;

const LoadingVideo = styled(VideoStretch)`
	position: absolute;
	object-position: 50% bottom;
	z-index: -2;
	opacity: 0;

	&.enter {
		opacity: 0;
	}
	&.enter-active {
		transition: opacity 400ms;
		transition-delay: 400ms;
		opacity: 1;
	}
	&.enter-done {
		opacity: 1;
	}
	&.exit {
		opacity: 1;
		z-index: -1;
	}
	&.exit-active {
		transition: opacity 400ms;
		opacity: 0;
		z-index: -1;
	}
`;

const LoadingText = styled.p`
	position: relative;
	color: white;
	font-family: ${(props) => props.theme.fonts.critical};
	opacity: 0;
	transition: opacity 400ms ease-in;
	display: none;
	letter-spacing: 1px;

	&.underline {
		&:after {
			position: absolute;
			top: 1.35em;
			width: 100%;
			content: "";
			display: block;
			height: 1px;
			background: white;
			animation: 1.6s ease-in-out infinite loopUnderline;

			@keyframes loopUnderline {
				from {
					transform: scaleX(0);
					transform-origin: left center;
				}

				48% {
					transform: scaleX(1);
					transform-origin: left center;
				}
				52% {
					transform: scaleX(1);
					transform-origin: right center;
				}

				to {
					transform: scaleX(0);
					transform-origin: right center;
				}
			}
		}
	}

	&.active {
		display: block;
		opacity: 1;
	}
`;

const PlayButton = styled(LoadingText.withComponent("button"))`
	position: absolute;
	top: 0;
	left: 0;
	color: white;
	background: none;
	width: 100%;
	height: 100%;
	border: none;
	cursor: pointer;
`;

const SkipButton = styled(LoadingText.withComponent("button"))`
	position: absolute;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
	border: 1px solid white;
	cursor: pointer;
	color: white;
	background: none;
	padding: 8px 12px 10px 12px;
`;

const LoadingWrapper = styled.div`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: ${(props) => props.theme.zIndices.loading};
	font-family: ${(props) => props.theme.fonts.heading};
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: black;
	transition: opacity ${(props) => props.theme.animations.loading.exit}ms;

	&:before {
		content: "";
		display: block;

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;

		width: 100%;
		height: 100%;

		background-color: white;
		transition: opacity ${(props) => props.theme.animations.loading.enter}ms
			ease-in;
	}

	.no-js & {
		opacity: 0;
		pointer-events: none;
		transition: opacity ${(props) => props.theme.animations.loading.exit}ms;
	}

	&.loading {
		&-enter:before {
			opacity: 1;
		}

		&-enter-active:before {
			opacity: 0;
		}

		&-enter-done,
		&-exit,
		&-exit-active {
			&:before {
				display: none;
			}
		}

		&-exit {
			opacity: 1;
		}
		&-exit-active {
			opacity: 0;
		}
		&-exit-done {
			display: none;
		}
	}
`;

type Props = {
	onExit?: () => void;
	onExited?: () => void;
};

const Loading: FC<Props> = ({ onExit = () => {}, onExited = () => {} }) => {
	const theme = useTheme();
	const { unmute } = useContext(MediaContext);
	const introPlayer = createRef<HTMLVideoElement>();
	const loadingPlayer = createRef<HTMLVideoElement>();
	const { muted } = useContext(MediaContext);
	const { startLoading } = useContext(LoadingContext);
	const fontReady = useFontFaceObserver([
		{ name: theme.fonts.body },
		{ name: theme.fonts.heading },
	]);

	// Loading Data
	const introSources = useMemo(
		() => [
			{
				mp4: withPrefix("/videos/mp4/2160/v2.mp4"),
				webm: withPrefix("/videos/webm/2160/v2.webm"),
				poster: withPrefix("/videos/poster/2160/v2.jpg"),
				mediaQuery: theme.breakpoints[3],
			},
			{
				mp4: withPrefix("/videos/mp4/1440/v2.mp4"),
				webm: withPrefix("/videos/webm/1440/v2.webm"),
				poster: withPrefix("/videos/poster/1440/v2.jpg"),
				mediaQuery: theme.breakpoints[2],
			},
			{
				mp4: withPrefix("/videos/mp4/1080/v2.mp4"),
				webm: withPrefix("/videos/webm/1080/v2.webm"),
				poster: withPrefix("/videos/poster/1080/v2.jpg"),
				mediaQuery: theme.breakpoints[1],
			},
			{
				mp4: withPrefix("/videos/mp4/720/v2.mp4"),
				webm: withPrefix("/videos/webm/720/v2.webm"),
				poster: withPrefix("/videos/poster/720/v2.jpg"),
				mediaQuery: theme.breakpoints[0],
			},
		],
		[]
	);

	const loadingSources = useMemo(
		() => [
			{
				mp4: withPrefix("/videos/mp4/2160/v1.mp4"),
				webm: withPrefix("/videos/webm/2160/v1.webm"),
				poster: withPrefix("/videos/poster/2160/v1.jpg"),
				mediaQuery: theme.breakpoints[3],
			},
			{
				mp4: withPrefix("/videos/mp4/1440/v1.mp4"),
				webm: withPrefix("/videos/webm/1440/v1.webm"),
				poster: withPrefix("/videos/poster/1440/v1.jpg"),
				mediaQuery: theme.breakpoints[2],
			},
			{
				mp4: withPrefix("/videos/mp4/1080/v1.mp4"),
				webm: withPrefix("/videos/webm/1080/v1.webm"),
				poster: withPrefix("/videos/poster/1080/v1.jpg"),
				mediaQuery: theme.breakpoints[1],
			},
			{
				mp4: withPrefix("/videos/mp4/720/v1.mp4"),
				webm: withPrefix("/videos/webm/720/v1.webm"),
				poster: withPrefix("/videos/poster/720/v1.jpg"),
				mediaQuery: theme.breakpoints[0],
			},
		],
		[]
	);

	// Set preloads
	usePreloads(
		loadingSources.map((m) => ({
			media: m.mediaQuery,
			as: "image",
			href: m.poster,
		}))
	);

	// Source by media query
	const [canPlayLoading, setCanPlayLoading] = useState(false);
	const introSource = useVideoArtDirection(introSources);
	const loadingSource = useVideoArtDirection(loadingSources);

	useLayoutEffect(() => {
		if (canPlayLoading && introPlayer.current) {
			loadingPlayer.current?.play().catch((e) => console.log(e));
			introPlayer.current.src = introSource.mp4;
			introPlayer.current.poster = introSource.poster;
			introPlayer.current.load();
		}
	}, [introSource, canPlayLoading]);

	useLayoutEffect(() => {
		if (loadingPlayer.current && fontReady) {
			loadingPlayer.current.src = loadingSource.mp4;
			loadingPlayer.current.poster = loadingSource.poster;
			loadingPlayer.current.load();
			startLoading();
		}
	}, [loadingSource, fontReady, startLoading]);

	// Animation
	const [active, setActive] = useState(false);

	// Mount : start when poster ready
	useEffect(() => {
		const i = new Image();
		i.onload = () => setActive(true);
		i.src = loadingSource.poster;
	}, [setActive]);

	// Playing video
	const [entered, setEntered] = useState(false);
	const [playing, setPlaying] = useState(false);
	useEffect(() => {
		if (playing && introPlayer.current && loadingPlayer.current) {
			unmute();
			// introPlayer.current.currentTime = 66;
			introPlayer.current.play();
			loadingPlayer.current.pause();
		}
	}, [unmute, playing]);

	// Play Button
	const [autoplay, setAutoplay] = useState(false);
	const [canPlay, setCanPlay] = useState(false);
	const ready = useMemo(
		() => canPlay && !playing && entered,
		[canPlay, playing, entered]
	);
	const waiting = useMemo(
		() => !ready && autoplay && !playing,
		[ready, autoplay, playing]
	);
	const skip = useMemo(() => playing, [playing]);

	return (
		<CSSTransition
			in={active}
			appear={true}
			timeout={{
				enter: theme.animations.loading.waiting,
				exit: theme.animations.page.enter,
			}}
			classNames={"loading"}
			onEntered={() => setEntered(true)}
			onExit={() => onExit()}
			onExited={() => {
				onExited();
				loadingPlayer.current?.pause();
				introPlayer.current?.pause();
			}}
		>
			<LoadingWrapper>
				<LoadingMute />
				<LoadingText className={waiting ? "active underline" : "underline"}>
					please wait
				</LoadingText>
				<PlayButton
					className={ready ? "active" : ""}
					onClick={() => setPlaying(true)}
				>
					click to ride
				</PlayButton>
				<SkipButton
					className={skip ? "active" : ""}
					onClick={() => setActive(false)}
				>
					skip
				</SkipButton>
				<AutoplayButton videoRef={loadingPlayer} />
				<CSSTransition appear={true} in={!playing} timeout={400}>
					<LoadingVideo
						ref={loadingPlayer}
						muted={muted}
						controls={false}
						playsInline={true}
						onTimeUpdate={() => setAutoplay(true)}
						onEnded={() => {
							loadingPlayer.current?.play();
						}}
						onCanPlayThrough={() => setCanPlayLoading(true)}
					/>
				</CSSTransition>
				<CSSTransition in={playing} timeout={400}>
					<LoadingVideo
						ref={introPlayer}
						muted={muted}
						controls={false}
						playsInline={true}
						onTimeUpdate={() => {
							if (introPlayer.current) {
								if (
									introPlayer.current.currentTime >=
									introPlayer.current.duration - 5
								) {
									setActive(false);
								}
							}
						}}
						onCanPlayThrough={() => setCanPlay(true)}
					/>
				</CSSTransition>
			</LoadingWrapper>
		</CSSTransition>
	);
};

export default Loading;
