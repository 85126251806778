import { useCallback, useEffect, useState } from "react";

const useFontFaceObserver = (
	fonts: {
		name: string;
		weight?: string | number;
	}[]
) => {
	const [isReady, setIsReady] = useState(false);

	const load = useCallback(async () => {
		const { default: FontFaceObserver } = await import(
			/* webpackChunkName: "preload" */
			/* webpackMode: "lazy" */
			/* webpackPreload: true */
			"fontfaceobserver"
		);

		return Promise.all(
			fonts.map((font) => {
				const { name, ...options } = font;
				const observer = new FontFaceObserver(name, options);
				return observer.load();
			})
		);
	}, [fonts]);

	useEffect(() => {
		load().finally(() => setIsReady(true));
	}, []);

	return isReady;
};

export default useFontFaceObserver;
