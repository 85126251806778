import React, { useCallback, useContext } from "react";
import { useTheme } from "@emotion/react";
import { FC, createContext, useMemo } from "react";
import { useMediaQueries, MediaQueryMatches } from "@react-hook/media-query";

interface Breakpoints extends MediaQueryMatches<{ [key: string]: any }> {
	getFirstMatch: (records: { [key: string]: any }, defaultValue: any) => any;
}

type breakpointsProps = Breakpoints;

const BreakpointsContext = createContext<breakpointsProps>({
	matches: {},
	matchesAny: false,
	matchesAll: false,
	getFirstMatch: () => false,
});

const BreakpointsProvider: FC<any> = ({ children }) => {
	const theme = useTheme();

	const queries: { [key: string]: string } = useMemo(() => {
		if (theme.breakpoints) {
			return theme.breakpoints.reduce(
				(o: Array<string>, i: string) => ({ ...o, [i]: i }),
				{ all: "all" }
			);
		} else return {};
	}, [theme]);

	const mediaQueries = useMediaQueries(queries);

	const getFirstMatch = useCallback(
		(records, defaultValue) => {
			const firstMatchKey = Object.keys(records).find(
				(key) => mediaQueries.matches[key]
			);
			return firstMatchKey ? records[firstMatchKey] : defaultValue;
		},
		[mediaQueries.matches]
	);

	return (
		<BreakpointsContext.Provider
			value={{
				...mediaQueries,
				getFirstMatch,
			}}
		>
			{children}
		</BreakpointsContext.Provider>
	);
};

const useBreakpoints = () => {
	return useContext(BreakpointsContext);
};

export { BreakpointsProvider, BreakpointsContext, useBreakpoints };
