import React, {
	FC,
	ComponentType,
	RefObject,
	useContext,
	useState,
	useEffect,
	createRef,
} from "react";
import { MediaContext } from "../contexts/MediaContext";

export type AutoplayProps = {
	active: boolean;
};

type AutoplayWrapperProps = {
	videoRef: RefObject<HTMLVideoElement>;
};

export default <P extends object>(
	WrappedComponent: ComponentType<P>
): FC<AutoplayWrapperProps> => {
	const withAutoplay = ({ videoRef, ...props }: AutoplayWrapperProps) => {
		const { autoplay } = useContext(MediaContext);
		const buttonRef = createRef<HTMLButtonElement>();
		const [active, setActive] = useState(false);

		useEffect(() => {
			if (!autoplay) {
				setActive(true);
				buttonRef.current?.addEventListener("click", () => {
					videoRef.current?.play().finally(() => setActive(false));
				});
			}
		}, [autoplay, setActive]);

		return (
			<WrappedComponent ref={buttonRef} active={active} {...(props as P)} />
		);
	};

	return withAutoplay;
};
