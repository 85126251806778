import styled from "@emotion/styled";
import ForwardVideo from "../elements/ForwardVideo";

export default styled(
	ForwardVideo({
		"data-object-fit": true,
	})
)`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;
